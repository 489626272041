import React from 'react';
import { H3, Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './SectionTextMaybe.module.css';
import IconCollection from '../../components/IconCollection/IconCollection';
import classNames from 'classnames';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false, serveFor } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  return text ? (
    <div className={css.sectionText}>
      <div>
        <div className={css.titleWrapper}>
          <H3 className={css.titleText}>{heading.split('(')[0]}</H3>
          {/* <IconCollection name="SHARE_ICON"/> */}
        </div>
        <p className={classNames(css.descriptionText, serveFor && css.serveDescription)}>{content}</p>
      </div>
    </div>
  ) : null;
};

export default SectionTextMaybe;
