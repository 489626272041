import React from 'react';

const IconCollection = ({ name }) => {
  switch (name) {

    case 'UPLOAD_ICON':
      return (
<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.75 36.25V45.9167C50.75 47.1985 50.2408 48.4279 49.3343 49.3343C48.4279 50.2408 47.1985 50.75 45.9167 50.75H12.0833C10.8015 50.75 9.57208 50.2408 8.66565 49.3343C7.75922 48.4279 7.25 47.1985 7.25 45.9167V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M41.0832 19.3333L28.9998 7.25L16.9165 19.3333" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29 7.25V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
             );
    case 'CLOCK_ICON':
      return (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" fill="#2E835F" fillOpacity="0.19"/>
<path d="M17 27C22.5228 27 27 22.5228 27 17C27 11.4772 22.5228 7 17 7C11.4772 7 7 11.4772 7 17C7 22.5228 11.4772 27 17 27Z" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 11V17L21 19" stroke="#2E835F" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
             );
    case 'uploadIcon':
      return (
<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.75 36.25V45.9167C50.75 47.1985 50.2408 48.4279 49.3343 49.3343C48.4279 50.2408 47.1985 50.75 45.9167 50.75H12.0833C10.8015 50.75 9.57208 50.2408 8.66565 49.3343C7.75922 48.4279 7.25 47.1985 7.25 45.9167V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M41.0834 19.3333L29 7.25L16.9167 19.3333" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29 7.25V36.25" stroke="#3D70A1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
             );
    case 'LOCATION_ICON':
      return (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" fill="#FFE1BE"/>
<path d="M24.5 15.333C24.5 21.1663 17 26.1663 17 26.1663C17 26.1663 9.5 21.1663 9.5 15.333C9.5 13.3439 10.2902 11.4362 11.6967 10.0297C13.1032 8.62318 15.0109 7.83301 17 7.83301C18.9891 7.83301 20.8968 8.62318 22.3033 10.0297C23.7098 11.4362 24.5 13.3439 24.5 15.333Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 17.833C18.3807 17.833 19.5 16.7137 19.5 15.333C19.5 13.9523 18.3807 12.833 17 12.833C15.6193 12.833 14.5 13.9523 14.5 15.333C14.5 16.7137 15.6193 17.833 17 17.833Z" stroke="#E28415" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
             );
    case 'SHARE_ICON':
      return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.58984 13.5098L15.4198 17.4898" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.4098 6.50977L8.58984 10.4898" stroke="#949494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

             );
    case 'LINE_ICON':
      return (
<svg width="89" height="1" viewBox="0 0 89 1" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="2.98431e-08" y1="0.5" x2="88.9835" y2="0.500011" stroke="#DBDBDB"/>
</svg>
             );
          default:
            return <></>;
        }
      };
      
      export default IconCollection;